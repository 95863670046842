/**
 * Created by ApolloLogix on 2020-09-09.
 */


//var html_selected
//var site_data
var page_to_fetch
let page_ready_alx = undefined

// check if the page has another page to fetch, this data is in the json data of the page
var current_page = window.location.pathname
console.log('current_page:', current_page)
fetch_data1(current_page, get_page_from_json_data, '', 'data/get_page_to_fetch')

/*


function waitForVariable() {
    return new Promise((resolve, reject) => {
        const checkVariable = () => {
            if (page_ready_alx !== undefined) {
                resolve(page_ready_alx);
            } else {
                setTimeout(checkVariable, 100); // Check again after 100 milliseconds
            }
        };

        checkVariable();
    });
}

// Usage: Wait for the variable to be set, then execute additional code
async function myFunction() {
    await waitForVariable();
    console.log('page_ready_alx is set 2305231332:', page_ready_alx);
    // Additional code here
    if (wp_styling) {
        fetch_data1('wp_styling', add_mdb_to_wp)
    }
}

myFunction();

*/


let myVariable = 1; // Set the variable
console.log('myVariable set 2305231332:', myVariable);
console.log('page_ready_alx 2305231332:', page_ready_alx);





function get_page_from_json_data(fetch_data) {
    // fetch_data contains the json
    //Array(1) [blog]
    if (fetch_data) {
        console.log('fetch_a_page')
        try {
            page_to_fetch = fetch_data[0]
        } catch (err) {
            page_to_fetch = false
        }
        if (page_to_fetch) {
            fetch_page(page_to_fetch);
        } else {
            console.log('error in fetch_data')
            new WOW().init();
            page_ready_alx = true
        }

    } else {
        console.log('no fetch_data found')
        new WOW().init();
        page_ready_alx = true
    }
}
